import { fetchEndpoint } from "@/server/network1";


//上传图片
export const uploadPicture = (data) => fetchEndpoint(
    "/school/device/uploadPicture", { ...data }, true, 'POST'
)


//获取学校信息
export const getSchoolDetail = (data) => fetchEndpoint(
    "/school/BaseInfoSchoolDetail/getSchoolDetail", { ...data }, true, 'POST'
)


//修改学校信息
export const updateSchoolDetail = (data) => fetchEndpoint(
    "/school/BaseInfoSchoolDetail/updateSchoolDetail", { ...data }, true, 'POST'
)