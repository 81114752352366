<template>
    <div class="school_info">
        <a-form-model
            :model="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
            <a-row>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="学校名称"
                    >
                        <a-input
                            :disabled="true"
                            v-model="form.schoolName"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="机构代码"
                    >
                        <a-input v-model="form.agencyCode" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="创办时间"
                    >
                        <a-date-picker
                            style="width:100%"
                            v-model="form.foundingTime"
                            @change="changeTime"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="学校类别"
                    >
                        <a-select v-model="form.category">
                            <a-select-option :value="1">公办</a-select-option>
                            <a-select-option :value="2">民办</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="党委书记"
                    >
                        <a-input v-model="form.partySecretary" />
                    </a-form-model-item>
                </a-col>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="校长"
                    >
                        <a-input v-model="form.principal" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="安全负责人"
                    >
                        <a-input v-model="form.securityOfficer" />
                    </a-form-model-item>
                </a-col>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="校方联系方式"
                    >
                        <a-input v-model="form.phone" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="职工人数"
                    >
                        <a-input v-model="form.teacherCount" />
                    </a-form-model-item>
                </a-col>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="学生人数"
                    >
                        <a-input v-model="form.studentCount" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="经度"
                    >
                        <a-input v-model="form.longitude" />
                    </a-form-model-item>
                </a-col>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="纬度"
                    >
                        <a-input v-model="form.latitude" />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="通讯地址"
                    >
                        <a-input
                            style="width: 200%"
                            v-model="form.postalAddress"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :md="12" :sm="24"> </a-col>
            </a-row>
            <a-row>
                <a-col :md="12" :sm="24">
                    <a-form-model-item
                        class="formItem"
                        :labelCol="labelCol"
                        :wrapperCol="wrapperCol"
                        label="学校logo"
                    >
                        <UploadImage @setImage="setImage" :maxNum="1" :imgList="imageUrl"/>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="12">
                <a-col :md="12" :sm="24">
                    <a-button
                        style="margin: 0 30%"
                        type="primary"
                        @click="editInfo"
                    >
                        保存
                    </a-button>
                </a-col>
            </a-row>
        </a-form-model>
    </div>
</template>

<script>
import { API_HOST } from "@/server/network1";
// import { getToken,removeToken } from '@/utils/cookies'
import router from "@/router";
import { uploadPicture, getSchoolDetail, updateSchoolDetail } from '@/api/schoolInfo'
import UploadImage from "@/component/uploadImage/uploadImage";

export default {
    name: "schoolInfo",
    data() {
        return {
            API_HOST,
            form: {
                schoolName : '',
                agencyCode : '',
                foundingTime : '',
                category : '',
                partySecretary : '',
                principal : '',
                securityOfficer : '',
                phone : '',
                teacherCount : '',
                studentCount : '',
                longitude : '',
                latitude : '',
                postalAddress : '',
            },
            labelCol: {
                xs: { span: 24 },
                sm: { span: 6 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 14 },
            },
            imageUrl : []
        };
    },
    components: {
        UploadImage
    },
    created() {
        this.getInfo()
    },
    methods: {
        openNotification: function(type, msg) {
            this.$notification[type]({
                message: "系统提示",
                description: msg,
            });
        },
        changeTime(date, dateString){
            this.form.foundingTime = dateString
        },
        setImage(val){
            this.imageUrl=val
        },
        getInfo(){
            getSchoolDetail({}).then(res=>{
                if(res.data.code == 200){
                    this.form = {
                        schoolName : res.data.data.schoolName,
                        agencyCode : res.data.data.agencyCode,
                        foundingTime : res.data.data.foundingTime,
                        category : res.data.data.category,
                        partySecretary : res.data.data.partySecretary,
                        principal : res.data.data.principal,
                        securityOfficer : res.data.data.securityOfficer,
                        phone : res.data.data.phone,
                        teacherCount : res.data.data.teacherCount,
                        studentCount : res.data.data.studentCount,
                        longitude : res.data.data.longitude,
                        latitude : res.data.data.latitude,
                        postalAddress : res.data.data.postalAddress,
                    }
                    this.imageUrl = res.data.data.photo?[]:[res.data.data.photo]
                }else{
                    this.openNotification('error', res.data.msg)
                }
            }).catch(err=>{
                this.openNotification('error', err.data.msg)
            })
        },
        editInfo(){
            let data = this.form
            data.photo = this.imageUrl.length > 0?this.imageUrl[0]:''
            updateSchoolDetail(data).then(res=>{
                if(res.data.code == 200){
                    this.openNotification('success', res.data.msg)
                    this.getInfo()
                }else{
                    this.openNotification('error', res.data.msg)
                }
            }).catch(res=>{
                this.openNotification('error', res.data.msg)
            })
        }
    },
};
</script>

<style lang="less" scoped>
.school_info {
    padding: 15px;
    background-color: white;
}
</style>
